import React from 'react';

import PersonIcon from '@mui/icons-material/Person';

import Masonry from '@mui/lab/Masonry';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useInterval } from '@tzmedical/react-hooks';

import axios from '../axiosClient.js';
import AddMember from './AddMember.jsx';
import ColumnPicker from './ColumnPicker.jsx';

const DATA_REFRESH_INTERVAL_MS = 3_600_000; // one hour

function GroupSettings() {
  const [groups, setGroups] = React.useState([]);
  const [timeIntervals, setTimeIntervals] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const handleGroups = React.useCallback(async () => {
    const [groupsQuery, intervalQuery] = await Promise.all([
      axios({
        method: 'GET',
        url: '/api/groups/',
      }),
      axios({
        method: 'GET',
        url: '/api/time-interval/',
      }),
    ]);
    setGroups(groupsQuery.data.map((group) => ({ memberCount: group.Users.length, ...group })));
    setTimeIntervals(intervalQuery.data);
    setLoading(false);
  }, []);

  useInterval(handleGroups, DATA_REFRESH_INTERVAL_MS, true);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Masonry>
      {groups.map((group) => (
        <Card sx={{ mr: 1, mt: 1 }} key={group.Name}>
          <CardHeader
            action={
              <Stack direction="row" alignItems="center">
                <PersonIcon fontSize="inherit" color="text.secondary" />
                <Typography sx={{ fontSize: 14 }} color="text.secondary">
                  {group.memberCount}
                </Typography>
              </Stack>
            }
            title={group.Name}
          />
          <CardContent>
            {group.Users.map((user) => {
              const fullName = `${user.FirstName} ${user.LastName}`;
              return <Chip key={fullName} label={fullName} variant="outlined" color="info" />;
            })}
          </CardContent>
          <CardActions>
            <ColumnPicker group={group} timeIntervals={timeIntervals} />
            <AddMember group={group} />
          </CardActions>
        </Card>
      ))}
    </Masonry>
  );
}

export default React.memo(GroupSettings);
