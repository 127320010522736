import React from 'react';

import ComputerIcon from '@mui/icons-material/Computer';
import DevicesOtherIcon from '@mui/icons-material/DevicesOther';
import InsightsIcon from '@mui/icons-material/Insights';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useInterval } from '@tzmedical/react-hooks';

import axios from '../axiosClient.js';
import Alert from './common/Alert.jsx';
import NavButton from './common/NavButton.jsx';

const DATA_REFRESH_INTERVAL_MS = 3_600_000; // one hour

const defaultGroupIcons = {
  Admin: <LocalLibraryIcon />,
  Mechanicals: <PrecisionManufacturingIcon />,
  CSR: <SupportAgentIcon />,
  SDR: <DevicesOtherIcon />,
  IT: <ComputerIcon />,
};

function SideBar({ onClick }) {
  const [groups, setGroups] = React.useState([]);
  const [version, setVersion] = React.useState('');
  const [error, setError] = React.useState(null);

  const fetchItems = React.useCallback(async () => {
    try {
      const [groupsQuery, versionQuery] = await Promise.all([
        axios({
          method: 'GET',
          url: '/api/groups',
        }),
        axios({
          method: 'GET',
          url: '/api/version',
        }),
      ]);
      // if in default groups ignore, if new add them
      const newGroupList = groupsQuery.data.map((group) => ({
        name: group.Name,
        url: `/calls/${encodeURIComponent(group.Id)}`,
        icon: defaultGroupIcons[group.Name] || <InsightsIcon />,
      }));

      setGroups(newGroupList);
      setVersion(versionQuery.data.version);
      setError(null);
    } catch (err) {
      setError(
        err.response?.data?.message ||
          err.response?.data ||
          err.message ||
          'Something bad happened...'
      );
    }
  }, []);
  useInterval(fetchItems, DATA_REFRESH_INTERVAL_MS, true);

  return (
    <>
      <Stack justifyContent="space-between" height="100%">
        <List onClick={onClick}>
          {groups.length ? (
            <List disablePadding>
              {groups.map((group) => (
                <NavButton
                  Icon={group.icon}
                  text={group.name}
                  to={group.url}
                  key={`sidebar-call-${group.name}`}
                />
              ))}
            </List>
          ) : (
            <CircularProgress />
          )}
        </List>
        <Typography
          data-cy="version-number"
          backgroundColor="action.hover"
          sx={{
            borderRadius: 2,
            fontSize: '0.75rem',
            opacity: 0.7,
            px: 2,
            width: 'fit-content',
          }}
        >
          v{version}
        </Typography>
      </Stack>
      <Alert message={error} setMessage={setError} level="error" variant="snackbar" />
    </>
  );
}

export default SideBar;
