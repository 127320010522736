import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';

import ThemePicker from './ThemePicker.jsx';

function AvatarMenu() {
  const { user, logout } = useAuth0();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const handleOpen = React.useCallback((event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  }, []);
  const handleClose = React.useCallback(() => setOpen(false), []);
  const handleLogout = React.useCallback(() => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  }, [logout]);

  return (
    <>
      <Button onClick={handleOpen}>
        <Avatar alt={user?.name} src={user?.picture} />
      </Button>
      <Menu
        id="avatar-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{ paper: { sx: { height: 272, width: 375, overflow: 'hidden' } } }}
      >
        <Box p={2}>
          <Grid container p={2} sx={{ alignItems: 'center', justifyContent: 'center' }}>
            <Grid item xs={5}>
              <Avatar
                alt={user?.name}
                src={user?.picture}
                sx={{
                  m: 2,
                  ml: '30px',
                  w: '75px',
                  h: '75px',
                }}
              />
            </Grid>
            <Grid item xs={7} data-cy="full-name-menu">
              <Typography marginBottom={2}>
                {user?.name}
                {user?.nickname && `(${user?.nickname})`}
              </Typography>
              <Typography marginBottom={2}>{user?.email}</Typography>
            </Grid>
          </Grid>
          <ThemePicker />
          <Grid container sx={{ alignItems: 'center', justifyContent: 'center', pt: 2 }}>
            <Grid item xs={6} align="center">
              <Button component={Link} to="/settings" m={1} onClick={handleClose}>
                Settings
              </Button>
            </Grid>
            <Grid item xs={6} align="center">
              <Button m={1} onClick={handleLogout} data-cy="sign-out-button">
                Sign Out
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Menu>
    </>
  );
}

export default AvatarMenu;
