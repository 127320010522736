import React from 'react';
import { useForm } from 'react-hook-form';

import ColumnIcon from '@mui/icons-material/ViewColumn';

import LoadingButton from '@mui/lab/LoadingButton';
import WarningAlert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import axios from '../axiosClient.js';
import Alert from './common/Alert.jsx';
import CancelButton from './common/CancelButton.jsx';
import FormCheckboxInput from './common/FormCheckboxInput.jsx';
import FormStringInput from './common/FormStringInput.jsx';

const bobAnimation = {
  animation: 'bobUpDown 5s ease infinite',
  position: 'relative',
};

const generateTimeRange = (startHour, endHour) => {
  const hoursInRange = endHour - startHour + 1;

  return Array.from({ length: hoursInRange }, (_, hour) => {
    const hourWithOffset = hour + startHour;
    const ampm = hourWithOffset < 12 ? 'am' : 'pm';
    const twelveHourFormat = hourWithOffset % 12 || 12;

    return {
      name: `${twelveHourFormat} ${ampm}`,
      id: hourWithOffset,
    };
  });
};

function ColumnPicker({ group, timeIntervals }) {
  const [error, setError] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [submitLoading, setSubmitLoading] = React.useState(false);
  const timeIntervalRef = React.useRef(timeIntervals);

  const callsPerHourId = React.useMemo(
    () => timeIntervalRef.current.find((interval) => interval.Name === 'Calls Per Hour')?.Id,
    []
  );
  const memoizedTimeIntervals = React.useMemo(() => {
    const timeIntervalOptions = timeIntervalRef.current.map((interval) => ({
      name: interval.Name,
      id: interval.Id,
      ...interval,
    }));
    if (group.Name !== 'Admin') {
      const emailsPerHourOption = timeIntervalOptions.find(
        ({ name }) => name === 'Emails Per Hour'
      );
      emailsPerHourOption.disabled = true;
    }
    return timeIntervalOptions;
  }, [group.Name]);

  const memoizedCallsPerHourRangeToView = React.useMemo(
    () => [
      { name: 'Working Hours', id: 'workingHours' },
      { name: 'All Hours', id: 'allHours' },
      { name: 'Custom', id: 'custom' },
    ],
    []
  );
  // 24 hours for users who want specific hours
  const memoizedCustomCallsPerHourRange = React.useMemo(() => generateTimeRange(0, 23), []);
  const defaultValues = React.useMemo(
    () => ({
      timeIntervalOptions: group.TimeIntervals.map(
        (interval) => timeIntervalRef.current.find(({ Name }) => Name === interval.Name).Id
      ),
      callsPerHourRangeToView: 'workingHours',
      callsPerHourToDisplay: [],
      splitCallsByDirection: Boolean(group.SplitCallsByDirection),
    }),
    [group.SplitCallsByDirection, group.TimeIntervals]
  );

  const {
    control,
    formState: { isDirty },
    handleSubmit,
    reset,
    watch,
  } = useForm(defaultValues);
  const handleOpen = React.useCallback(() => setOpen(true), [setOpen]);
  const handleClose = React.useCallback(() => setOpen(false), [setOpen]);

  const watchedValues = watch();

  const callsPerHourSelected = React.useMemo(
    () => watchedValues.timeIntervalOptions?.some((Id) => Id === callsPerHourId),
    [callsPerHourId, watchedValues.timeIntervalOptions]
  );
  const customHoursRangeSelected = React.useMemo(
    () => watchedValues.callsPerHourRangeToView === 'custom',
    [watchedValues.callsPerHourRangeToView]
  );
  const onSubmit = React.useCallback(
    async (values) => {
      setSubmitLoading(true);
      let { customCallsPerHourRange } = values;
      const { callsPerHourRangeToView, splitCallsByDirection, timeIntervalOptions } = values;

      // determine if a hour preset was selected
      if (callsPerHourRangeToView === 'workingHours') {
        customCallsPerHourRange = generateTimeRange(6, 16);
      } else if (callsPerHourRangeToView === 'allHours') {
        customCallsPerHourRange = memoizedCustomCallsPerHourRange;
      }

      try {
        await axios({
          method: 'PUT',
          url: `/api/groups/${group.Id}`,
          data: {
            customCallsPerHourRange,
            splitCallsByDirection,
            timeIntervals: timeIntervalOptions,
          },
        });

        reset();
        setSubmitLoading(false);
        setOpen(false);
      } catch (err) {
        setError(err.response?.data?.message || err.message);
        setSubmitLoading(false);
      }
    },
    [group.Id, memoizedCustomCallsPerHourRange, reset]
  );

  return (
    <>
      <Button onClick={handleOpen} startIcon={<ColumnIcon />}>
        Change Columns
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <Alert message={error} setMessage={setError} level="error" data-cy="error-alert" />
        <DialogTitle sx={{ textTransform: 'capitalize' }}>
          <Stack direction="row" spacing={2} alignItems="center">
            <ColumnIcon />
            Column Picker: {group.Name}
          </Stack>
        </DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <DialogContent>
            {watch('columns')?.length > 3 && (
              <WarningAlert severity="warning" sx={bobAnimation}>
                <AlertTitle>Caution!</AlertTitle>
                Selecting more than four columns per group may create unexpected behavior, select at
                your own risk!
              </WarningAlert>
            )}
            <DialogContentText pb={2}>
              Define the default columns to be viewed by members with access to the {group.Name}{' '}
              group
            </DialogContentText>
            <Grid container columns={2} spacing={2}>
              <Grid item xs={1}>
                <FormStringInput
                  control={control}
                  label="Columns To View"
                  name="timeIntervalOptions"
                  defaultValue={defaultValues.timeIntervalOptions}
                  options={memoizedTimeIntervals}
                  selectMultiple
                />
              </Grid>
              <Grid item xs={1}>
                <FormCheckboxInput
                  control={control}
                  label="Split Direction (Inbound/Outbound)"
                  name="splitCallsByDirection"
                  defaultValue={defaultValues.splitCallsByDirection}
                />
              </Grid>
              {callsPerHourSelected && (
                <Grid item xs={1}>
                  <FormStringInput
                    control={control}
                    label="Hour Range To View"
                    name="callsPerHourRangeToView"
                    defaultValue={defaultValues.callsPerHourRangeToView}
                    options={memoizedCallsPerHourRangeToView}
                    disablePortal
                  />
                </Grid>
              )}
              {customHoursRangeSelected && (
                <Grid item xs={1}>
                  <FormStringInput
                    control={control}
                    label="Custom Hours Range"
                    name="customCallsPerHourRange"
                    defaultValue={defaultValues.callsPerHourToDisplay}
                    options={memoizedCustomCallsPerHourRange}
                    autocomplete
                    selectMultiple
                    checkbox
                    disableCloseOnSelect
                  />
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Box m={2}>
              <CancelButton color="secondary" isDirty={isDirty} onClick={handleClose}>
                Cancel
              </CancelButton>
            </Box>
            <Box m={2}>
              <LoadingButton
                id={`${group.Name}-group-pref-submit`}
                variant="contained"
                color="secondary"
                loading={submitLoading}
                type="submit"
              >
                Submit
              </LoadingButton>
            </Box>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

export default React.memo(ColumnPicker);
