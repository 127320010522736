import React from 'react';
import { DateTime } from 'luxon';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import { alpha, useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers';

import { useInterval } from '@tzmedical/react-hooks';

import axios from '../axiosClient.js';
import Alert from './common/Alert.jsx';

const DATA_REFRESH_INTERVAL_MS = 30_000;

function CallsPerHour({ visibleHours, groupId }) {
  const [loading, setLoading] = React.useState(true);
  const [callsPerHour, setCallsPerHour] = React.useState([]);
  const [callsPerHourTotals, setCallsPerHourTotals] = React.useState([]);
  const [error, setError] = React.useState(null);
  const [selectedDate, setSelectedDate] = React.useState(DateTime.local());

  const formattedDate = React.useMemo(() => {
    let date;
    if (selectedDate instanceof Date) {
      date = DateTime.fromJSDate(selectedDate).startOf('day').toISODate();
    } else {
      date = selectedDate.startOf('day').toISODate();
    }
    return date;
  }, [selectedDate]);
  const [dialogMessage, setDialogMessage] = React.useState(null);

  const theme = useTheme();
  const baseIntensity = React.useMemo(() => theme.palette.error[theme.palette.mode], [theme]);

  const determineCallCountColors = React.useCallback(
    (userData) => {
      let highestCallCount = 0;
      userData.forEach((user) =>
        Object.values(user.totals).forEach((hour) => {
          highestCallCount = Math.max(highestCallCount, hour);
        })
      );

      userData.forEach((user) => {
        Object.keys(user.totals).forEach((hour) => {
          const normalizedCount = user.totals[hour] / highestCallCount;
          if (!user.formattedTotals) {
            /* eslint-disable-next-line no-param-reassign */
            user.formattedTotals = {};
          }
          /* eslint-disable-next-line no-param-reassign */
          user.formattedTotals[hour] = {
            total: user.totals[hour],
            backgroundColor: alpha(baseIntensity, normalizedCount),
          };

          /* eslint-disable-next-line no-param-reassign */
          user.totalCallsInRange = Object.values(user.totals).reduce(
            (accum, total) => accum + total,
            0
          );
        });
      });

      return userData;
    },
    [baseIntensity]
  );

  const determineCallsPerHourTotals = React.useCallback(
    (userData) => {
      const runningTotals = {};

      visibleHours.forEach((hour) => {
        runningTotals[hour] = Object.values(userData).reduce(
          (accum, next) => accum + next.totals[hour],
          0
        );
      });

      runningTotals.total = Object.values(runningTotals).reduce((accum, next) => accum + next, 0);

      return runningTotals;
    },
    [visibleHours]
  );

  const getCallsPerHour = React.useCallback(async () => {
    try {
      if (!error) {
        const { data } = await axios({
          method: 'GET',
          url: `/api/calls/summary/calls-per-hour/${groupId}/${formattedDate}`,
        });
        setCallsPerHour(determineCallCountColors(data));
        setCallsPerHourTotals(determineCallsPerHourTotals(data));
        setError(null);
        setLoading(false);
      }
    } catch (err) {
      setError(err.response?.data?.message || err.message);
    }
  }, [determineCallCountColors, determineCallsPerHourTotals, error, groupId, formattedDate]);

  const downloadCSV = React.useCallback(async () => {
    try {
      const { data } = await axios({
        method: 'GET',
        url: `/api/calls/summary/calls-per-hour/${groupId}/${formattedDate}?csv=true`,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          Accept: 'text/csv',
        },
      });
      const blob = new Blob([data], { type: 'text/csv' });
      const element = document.createElement('a');

      element.href = URL.createObjectURL(blob);
      element.download = `${formattedDate}-${groupId}-calls`;

      element.click();
    } catch (err) {
      setError(err?.message);
    }
  }, [formattedDate, groupId]);

  useInterval(getCallsPerHour, DATA_REFRESH_INTERVAL_MS, loading && selectedDate);

  // Ensure loading is set to true when groupId changes
  React.useEffect(() => setLoading(true), [groupId]);

  const handleDateChange = React.useCallback((date) => {
    setSelectedDate(date);
    setLoading(true);
  }, []);

  return (
    <>
      <Alert
        data-cy="dialog-alert"
        message={dialogMessage}
        setMessage={setDialogMessage}
        level="info"
        variant="dialog"
      />
      <Alert data-cy="error-alert" message={error} setMessage={setError} level="error" />
      <Stack direction="row" justifyContent="flext-start" alignItems="center" mb={2}>
        <Typography variant="h5" mr={2}>
          Calls Per Hour
        </Typography>
        <DatePicker
          defaultValue={selectedDate}
          onChange={handleDateChange}
          slotProps={{
            inputAdornment: {
              position: 'start',
            },
          }}
        />
        <Box flexGrow={1} />

        <Button variant="contained" color="primary" onClick={downloadCSV}>
          Export
        </Button>
      </Stack>
      {loading ? (
        <CircularProgress size={90} />
      ) : (
        <Table sx={{ tableLayout: 'fixed', mb: 2 }}>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              {visibleHours.map((hour) => {
                let formattedHour;
                if (hour === 0) {
                  formattedHour = '12 am';
                } else if (hour % 12 === 0) {
                  formattedHour = '12 pm';
                } else {
                  const ampm = hour < 12 ? 'am' : 'pm';
                  const boundedHour = hour % 12;
                  formattedHour = `${boundedHour} ${ampm}`;
                }

                return (
                  <TableCell key={formattedHour} sx={{ textAlign: 'center' }}>
                    {formattedHour}
                  </TableCell>
                );
              })}
              <TableCell>Total Calls Made in Range</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {callsPerHour.map((user) => {
              const name = `${user.FirstName} ${user.LastName}${user.direction ? `(${user.direction})` : ''}`;
              return (
                <TableRow key={name}>
                  <TableCell>{name}</TableCell>
                  {Object.keys(user.formattedTotals).map((hour) => {
                    const userHourData = user.formattedTotals[hour];
                    return (
                      <TableCell
                        key={`${name}-${hour}`}
                        sx={{
                          backgroundColor: userHourData.backgroundColor,
                          textAlign: 'center',
                        }}
                      >
                        {userHourData.total || '-'}
                      </TableCell>
                    );
                  })}
                  <TableCell sx={{ textAlign: 'center' }}>
                    {user.totalCallsInRange || '-'}
                  </TableCell>
                </TableRow>
              );
            })}
            <TableRow>
              <TableCell>Totals</TableCell>
              {Object.values(callsPerHourTotals).map((hour, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <TableCell sx={{ textAlign: 'center' }} key={`calls-per-hour-total-${index}`}>
                  {hour || '-'}
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      )}
    </>
  );
}

export default CallsPerHour;
