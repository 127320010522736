import React from 'react';
import { useParams } from 'react-router-dom';
import { DateTime } from 'luxon';

import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

import { useInterval } from '@tzmedical/react-hooks';

import axios from '../../axiosClient.js';
import CallListColumn from '../../components/CallListColumn.jsx';
import CallsPerHour from '../../components/CallsPerHour.jsx';
import EmailsPerHour from '../../components/EmailsPerHour.jsx';

const DATA_REFRESH_INTERVAL_MS = 30_000;

function CallCounts() {
  const { groupId } = useParams();
  const [callList, setCallList] = React.useState([]);
  const [columnsToDisplay, setColumnsToDisplay] = React.useState({});
  const [error, setError] = React.useState(null);
  const [visibleHours, setVisibleHours] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const today = React.useMemo(() => DateTime.now(), []);
  const formattedToday = React.useMemo(() => DateTime.now().toFormat('LLL dd'), []);
  const columnTooltips = React.useMemo(
    () => ({
      today: `Total calls made today from ${today.startOf('day').toFormat('h:mm a')} to ${today.toFormat('h:mm a')}`,
      week: `Total calls made from the start of the this week: ${today.startOf('week').toFormat('LLL dd')} to today: ${formattedToday}`,
      rollingWeek: `Total calls made from seven days ago: ${today.minus({ week: 1 }).toFormat('LLL dd')} to today at ${today.toFormat('h:mm a')}`,
      lastWeek: `Total calls made from the start of last week: ${today.minus({ weeks: 1 }).startOf('week').toFormat('LLL dd')} to the end of last week: ${today.minus({ weeks: 1 }).endOf('week').minus({ days: 2 }).toFormat('LLL dd')}`,
      month: `Total calls made from the start of the month: ${today.startOf('month').toFormat('LLL dd')} to today: ${formattedToday}`,
      rollingMonth: `Total calls made from one month ago: ${today.minus({ month: 1 }).toFormat('LLL dd')} to today: ${formattedToday}`,
      year: `Total calls made from the start of the year: ${today.startOf('year').toFormat('LLL dd')} to today: ${formattedToday}`,
      rollingYear: `Total calls made from one year go: ${today.minus({ year: 1 }).toFormat('LLL dd, yyyy')} to today: ${today.toFormat('LLL dd, yyyy')}`,
    }),
    [formattedToday, today]
  );

  const getCallCounts = React.useCallback(async () => {
    try {
      if (!error) {
        const [callsQuery, visibleHoursQuery] = await Promise.all([
          axios({
            method: 'GET',
            // we can get away with not manually encoding this url as
            // axios automagically handles this for us
            url: `/api/calls/summary/${groupId}`,
          }),
          axios({
            method: 'GET',
            url: `/api/groups/${groupId}`,
          }),
        ]);
        setVisibleHours(visibleHoursQuery.data.visibleHours);
        setCallList(callsQuery.data);
        setColumnsToDisplay(visibleHoursQuery.data.timeIntervals);

        setError(null);
        setLoading(false);
      }
    } catch (err) {
      setError(err.response?.data?.message || err.message);
    }
  }, [error, groupId, setError, setCallList]);
  useInterval(getCallCounts, DATA_REFRESH_INTERVAL_MS, groupId);

  // set loading state only if the group changes
  React.useEffect(() => setLoading(true), [groupId]);

  if (loading) {
    return (
      <div style={{ position: 'absolute', top: '40%', left: '50%' }}>
        <CircularProgress size={90} />
      </div>
    );
  }

  return (
    <>
      {columnsToDisplay['Calls Per Hour'] && (
        <CallsPerHour groupId={groupId} visibleHours={visibleHours} />
      )}
      {columnsToDisplay['Emails Per Hour'] && <EmailsPerHour groupId={groupId} />}
      <Stack direction={{ sm: 'column', lg: 'row' }} spacing={2} justifyContent="space-evenly">
        {columnsToDisplay.Today && (
          <CallListColumn
            title="Today"
            callList={callList?.today}
            totalCalls={callList?.totals?.today}
            tooltip={columnTooltips.today}
          />
        )}
        {columnsToDisplay.Week && (
          <CallListColumn
            title="Week"
            callList={callList?.week}
            totalCalls={callList?.totals?.week}
            tooltip={columnTooltips.week}
          />
        )}
        {columnsToDisplay['Last Week'] && (
          <CallListColumn
            title="Last Week"
            callList={callList?.lastWeek}
            totalCalls={callList?.totals?.lastWeek}
            tooltip={columnTooltips.lastWeek}
          />
        )}
        {columnsToDisplay['Rolling Week'] && (
          <CallListColumn
            title="Rolling Week"
            callList={callList?.rollingWeek}
            totalCalls={callList?.totals?.rollingWeek}
            tooltip={columnTooltips.rollingWeek}
          />
        )}
        {columnsToDisplay.Month && (
          <CallListColumn
            title="Month"
            callList={callList?.month}
            totalCalls={callList?.totals?.month}
            tooltip={columnTooltips.month}
          />
        )}
        {columnsToDisplay['Rolling Month'] && (
          <CallListColumn
            title="Rolling Month"
            callList={callList?.rollingMonth}
            totalCalls={callList?.totals?.rollingMonth}
            tooltip={columnTooltips.rollingMonth}
          />
        )}
        {columnsToDisplay.Year && (
          <CallListColumn
            title="Year"
            callList={callList?.year}
            totalCalls={callList?.totals?.year}
            tooltip={columnTooltips.year}
          />
        )}
        {columnsToDisplay['Rolling Year'] && (
          <CallListColumn
            title="Rolling Year"
            callList={callList?.rollingYear}
            totalCalls={callList?.totals?.rollingYear}
            tooltip={columnTooltips.rollingYear}
          />
        )}
      </Stack>
    </>
  );
}

export default CallCounts;
