import React from 'react';
import { DateTime } from 'luxon';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { alpha, useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers';

import { useInterval } from '@tzmedical/react-hooks';

import axios from '../axiosClient.js';

const DATA_REFRESH_INTERVAL_MS = 300_000;

function EmailsPerHour({ groupId }) {
  const [emailsPerHour, setEmailsPerHour] = React.useState([]);
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [selectedDate, setSelectedDate] = React.useState(DateTime.local());

  const theme = useTheme();
  const baseIntensity = React.useMemo(() => theme.palette.error[theme.palette.mode], [theme]);

  const totalEmails = React.useMemo(
    () =>
      Object.values(emailsPerHour).reduce((accumulator, current) => accumulator + current.total, 0),
    [emailsPerHour]
  );

  const determineEmailsPerHourColors = React.useCallback(
    (emailData) => {
      const highestEmailCount = Math.max(...Object.values(emailData), 0);

      const emailCountWithColors = Object.keys(emailData).map((hour) => {
        const normalizedTotal = emailData[hour] / highestEmailCount;
        const hourWithColor = {
          hour,
          total: emailData[hour],
          backgroundColor: alpha(baseIntensity, normalizedTotal),
        };

        return hourWithColor;
      });

      return emailCountWithColors;
    },
    [baseIntensity]
  );

  const getEmailsPerHour = React.useCallback(async () => {
    try {
      if (!error) {
        const { data } = await axios({
          method: 'GET',
          url: '/api/emails/summary',
        });

        setEmailsPerHour(determineEmailsPerHourColors(data));
        setLoading(false);
      }
    } catch (err) {
      setError(err.response?.data?.message || err.message);
    }
  }, [determineEmailsPerHourColors, error]);

  useInterval(getEmailsPerHour, DATA_REFRESH_INTERVAL_MS, loading && selectedDate);

  React.useEffect(() => setLoading(true), [groupId]);

  const handleDateChange = React.useCallback((date) => {
    setSelectedDate(date);
    setLoading(true); // Trigger re-fetch
  }, []);

  return (
    <>
      <Box display="flex" alignItems="center" mb={2}>
        <Typography variant="h5" mr={2}>
          Emails Per Hour
        </Typography>
        <DatePicker
          defaultValue={selectedDate}
          onChange={handleDateChange}
          slotProps={{
            inputAdornment: {
              position: 'start',
            },
          }}
        />
      </Box>
      {loading ? (
        <CircularProgress size={90} />
      ) : (
        <Table size="small" sx={{ tableLayout: 'fixed', mb: 2 }}>
          <TableHead>
            <TableRow>
              {Object.values(emailsPerHour).map(({ hour }) => {
                let prettyHour;
                if (Number(hour) === 0) {
                  prettyHour = '12 am';
                } else if (hour % 12 === 0) {
                  prettyHour = '12 pm';
                } else {
                  prettyHour = `${hour % 12} ${hour < 12 ? 'am' : 'pm'}`;
                }

                return <TableCell key={prettyHour}>{prettyHour}</TableCell>;
              })}
              <TableCell>Total Emails</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {emailsPerHour.map((hourData) => {
                return (
                  <TableCell
                    sx={{
                      backgroundColor: hourData.backgroundColor,
                      textAlign: 'center',
                    }}
                    key={`email-${hourData.hour}`}
                  >
                    {hourData.total || '-'}
                  </TableCell>
                );
              })}
              <TableCell>{totalEmails}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
    </>
  );
}

export default EmailsPerHour;
