import React from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import useTheme from '@mui/material/styles/useTheme';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Zoom from '@mui/material/Zoom';

import { useSort } from '@tzmedical/react-hooks';

import ColumnHeader from './common/ColumnHeader.jsx';

const sortOptions = {
  defaultSort: { field: 'FirstName', reverse: false },
};

function CallListColumn({ callList = [], totalCalls, title, tooltip }) {
  const [sortedCallList, handleSortSelection, sort] = useSort(callList, sortOptions);
  const theme = useTheme();

  return (
    <Box sx={{ w: '100%' }}>
      <Tooltip
        TransitionComponent={Zoom}
        TransitionProps={{ timeout: 600 }}
        title={tooltip}
        followCursor
      >
        <Typography variant="h5">{title}</Typography>
      </Tooltip>
      {!totalCalls ? (
        'No Call Data Found...'
      ) : (
        <TableContainer
          component={Paper}
          variant="outlined"
          elevation={0}
          sx={{ borderRadius: 2, mt: 1, boxShadow: 'none' }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <ColumnHeader
                    id="FirstName"
                    display="Name"
                    sortField={sort.field}
                    reverseSort={sort.reverse}
                    onClick={handleSortSelection}
                  />
                </TableCell>
                <TableCell>
                  <ColumnHeader
                    id="CallCount"
                    display="Calls"
                    sortField={sort.field}
                    reverseSort={sort.reverse}
                    onClick={handleSortSelection}
                  />
                </TableCell>
                <TableCell>
                  <ColumnHeader
                    id="TotalDurationMS"
                    display="Duration"
                    sortField={sort.field}
                    reverseSort={sort.reverse}
                    onClick={handleSortSelection}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedCallList?.map((user) => (
                <TableRow
                  key={`${user.Id}-${user.CallCount}-${user.Direction}`}
                  sx={{
                    '&:nth-of-type(odd)': {
                      backgroundColor: theme.palette.action.hover,
                    },
                  }}
                >
                  <TableCell>
                    {user.FirstName} {user.LastName}
                    {user.Direction && ` - ${user.Direction}`}
                  </TableCell>
                  <TableCell>{user?.CallCount || '-'}</TableCell>
                  <TableCell>{user?.TotalDuration || '-'}</TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell sx={{ borderBottom: 'none' }}>Total</TableCell>
                <TableCell sx={{ borderBottom: 'none' }}>{totalCalls.TotalCalls}</TableCell>
                <TableCell sx={{ borderBottom: 'none' }}>{totalCalls.TotalDuration}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
}

export default React.memo(CallListColumn);
